<template>
  <div class="col-sm border m-3 shadow bg-light" style="max-width: 500px">
    <div class="card-body text-center">
      <h5><i class="fas fa-trash"></i> Eliminar</h5>
      <hr>
      <div>
        <div class="form-group">
          <p>Elige la pelicula:</p>
          <Multiselect
            v-model="selectedFolder"
            :close-on-select="true"
            :options="folders"
            @search-change="searchFolder"
            placeholder="Busca una pelicula"
            :internal-search="false"
            :clear-on-select="true"
            @input="getFiles"
          >
          </Multiselect>
        </div>

        <p v-show="errorFiles">Error obteniendo los archivos de la película</p>

        <h3 v-show="loadingFiles" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

        <div v-if="!errorFiles && !loadingFiles && files" class="">
          <div v-for="file in files" :key="file" class="text-left">
            <p>
              <LoadingButton @clicked="eliminarRuta(selectedFolder + '/' + file)" :iconProp="'fas fa-trash-alt'" :loadingProp="loadingEliminar" :classProp="'btn btn-danger'"></LoadingButton>
               - {{ file }}
            </p>
          </div>

          <LoadingButton @clicked="eliminarRuta(selectedFolder)" :iconProp="'fas fa-trash-alt'" :loadingProp="loadingEliminar" :classProp="'btn btn-danger'" :textProp="'Eliminar carpeta'"></LoadingButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Multiselect from 'vue-multiselect'
  import LoadingButton from '../LoadingButton.vue'
  import Swal from 'sweetalert2'
  import config from '../../config'

  export default {
    data() {
      return {
        loadingEliminar: false,
        loadingFiles: false,
        errorFiles: false,
        selectedFolder: null,
        folders: [],
        files: null
      }
    },
    components: {
      Multiselect,
      LoadingButton
    },
    methods: {
      async searchFolder(query) {
        try {
          if (!query) {
            return
          }

          const res = await axios.get(config.apiUrl + '/herramientas/peliculas/buscar?query=' + query)

          this.folders = res.data

        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: 'Ha habido un error buscando la película, inténtalo más tarde',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async getFiles() {
        try {
          this.loadingFiles = true
          const res = await axios.get(config.apiUrl + '/archivos?path=' + this.selectedFolder)
          this.loadingFiles = false
          this.files = res.data
        } catch (e) {
          this.loadingFiles = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async eliminarRuta(path) {
        try {
          this.loadingEliminar = true
          const res = await axios.post(config.apiUrl + '/archivos/eliminar', {
            path
          })
          this.loadingEliminar = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        } catch (e) {
          this.loadingEliminar = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
