<template>
  <div class="col-sm border m-3 shadow bg-light" style="max-width: 500px">
    <div class="card-body text-center">
      <h5><i class="fas fa-i-cursor"></i> Cambiar titulo de pelicula (archivo)</h5>
      <hr>
      <div>
        <form id="renombrarpelicula">
          <div class="form-group">
            <p>Elige la pelicula:</p>
            <Multiselect
              v-model="selectedFolder"
              :close-on-select="true"
              :options="folders"
              @search-change="searchFolder"
              placeholder="Busca una pelicula"
              :internal-search="false"
              :clear-on-select="true"
            >
            </Multiselect>
          </div>

          <div class="form-group">
            <p>Elige el nuevo nombre:</p>
            <Multiselect
              v-model="selectedOption"
              :close-on-select="true"
              :options="options"
              @search-change="searchTmdb"
              placeholder="Busca una película"
              :internal-search="false"
              :clear-on-select="true"
              :custom-label="nameWithYear"
            >
              <template
                slot="option"
                slot-scope="props"
              >
                <img
                  class="option__image"
                  :src="'https://image.tmdb.org/t/p/h100' + props.option.poster_path"
                  :alt="props.option.title"
                >
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.title }} ({{ props.option.release_date }})
                  </span>
                </span>
              </template>
            </Multiselect>
          </div>

          <div class="form-group">
            <p>¿Poner titulo original?</p>
            <input type="checkbox" v-model="ingles">
          </div>

          <div class="form-group">
            <LoadingButton @clicked="cambiarTitulo" :iconProp="'fas fa-edit'" :loadingProp="loadingCambiarTitulo" :classProp="'btn btn-outline-info'" :textProp="'Cambiar título'"></LoadingButton>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import LoadingButton from '../LoadingButton.vue'
  import Swal from 'sweetalert2'
  import _ from 'lodash'
  import axios from 'axios'
  import config from '../../config'

  export default {
    data() {
      return {
        loadingCambiarTitulo: false,
        selectedOption: null,
        options: [],
        ingles: false,
        selectedFolder: null,
        folders: []
      }
    },
    components: {
      Multiselect,
      LoadingButton
    },
    methods: {
      async cambiarTitulo() {
        try {
          this.loadingCambiarTitulo = true
          const res = await axios.post(config.apiUrl + '/herramientas/peliculas/renombrar', {
            tmdbId: this.selectedOption.id,
            ingles: this.ingles,
            carpeta: this.selectedFolder
          })
          this.loadingCambiarTitulo = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        } catch (e) {
          this.loadingCambiarTitulo = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      searchTmdb: _.debounce(async function(query) {
        try {

          if (!query) {
            return
          }

          const res = await axios.get('https://api.themoviedb.org/3/search/movie?language=es&api_key=34213f6650be159c39dfe30df7ecb001&query=' + query)

          this.options = res.data.results
        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: 'Ha habido un error buscando la película, inténtalo más tarde',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }, 200),
      nameWithYear(option) {
        return `${option.title} (${option.release_date})`
      },
      async searchFolder(query) {
        try {
          if (!query) {
            return
          }

          const res = await axios.get(config.apiUrl + '/herramientas/peliculas/buscar?query=' + query)

          this.folders = res.data

        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: 'Ha habido un error buscando la película, inténtalo más tarde',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
