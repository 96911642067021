<template>
  <div>
    <h1 class="mt-4 mb-4">Herramientas</h1>

    <p>Panel para gestionar cositas del servidor.</p>

    <div class="row">
      <div class="col-sm-auto">
        <LoadingButton @clicked="actualizarBiblioteca" :iconProp="'fas fa-sync'" :loadingProp="loadingAction" :classProp="'btn btn-success'" :textProp="'Actualizar biblioteca'"></LoadingButton>
      </div>

      <div class="col-sm-auto">
        <LoadingButton @clicked="buscarDuplicadas" :iconProp="'fas fa-copy'" :loadingProp="loadingAction" :classProp="'btn btn-outline-primary'" :textProp="'Buscar peliculas duplicadas'"></LoadingButton>
      </div>

      <div class="col-sm-auto">
        <LoadingButton @clicked="buscarFaltantes" :iconProp="'fas fa-times'" :loadingProp="loadingAction" :classProp="'btn btn-outline-primary'" :textProp="'Buscar peliculas faltantes'"></LoadingButton>
      </div>
    </div>

    <div class="row">

      <CambiarTituloPelicula></CambiarTituloPelicula>

      <EliminarPelicula></EliminarPelicula>

    </div>
  </div>
</template>

<script>
  import CambiarTituloPelicula from '../components/herramientas/CambiarTituloPelicula.vue'
  import EliminarPelicula from '../components/herramientas/EliminarPelicula.vue'
  import Swal from 'sweetalert2'
  import axios from 'axios'
  import LoadingButton from '../components/LoadingButton.vue'
  import config from '../config'

  export default {
    data() {
      return {
        loadingAction: false
      }
    },
    components: {
      CambiarTituloPelicula,
      EliminarPelicula,
      LoadingButton
    },
    methods: {
      async actualizarBiblioteca() {
        try {
          this.loadingAction = true
          const res = await axios.post(config.apiUrl + '/emby/biblioteca/actualizar')
          this.loadingAction = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        } catch (e) {
          this.loadingAction = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async buscarFaltantes() {
        try {
          this.loadingAction = true
          const res = await axios.get(config.apiUrl + '/herramientas/peliculas/faltantes')
          this.loadingAction = false

          const movies = res.data.join('<br>')

          Swal.fire({
            html: `
            <strong>Total: ${res.data.length}</strong>
            <div class="text-left">
              ${movies}
            </div>
            `,
          })
        } catch (e) {
          this.loadingAction = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async buscarDuplicadas() {
        try {
          this.loadingAction = true
          const res = await axios.get(config.apiUrl + '/herramientas/peliculas/duplicadas')
          this.loadingAction = false

          const movies = res.data.join('<br>')

          Swal.fire({
            html: `
            <strong>Total: ${res.data.length}</strong>
            <div class="text-left">
              ${movies}
            </div>
            `,
          })
        } catch (e) {
          this.loadingAction = false
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>
